@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {font-family: "Helvetica Neue"; src: url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.eot"); src: url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.woff") format("woff"), url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/365834cfa7beb7ca64c00476397ddc32.svg#Helvetica Neue") format("svg"); }
:root{
    --Mulish :"Mulish", system-ui !important;
}
p{
    font-family: var(--Mulish) !important;
}
.MuiInputBase-formControl{
    font-family: var(--Mulish) !important;
    font-weight: 400 !important;
}
.pageBg{
    background: url(./assert/image/patternBgLength.png) repeat top left;
    min-height: 100vh;
}
img{
    width:auto;
    max-width: 100%;
    vertical-align: bottom;
}
.min-w-0{
    min-width: auto !important;
}
.headingflower{
    width: 100%;
    overflow: hidden;
}
.headingflower img{
    flex : 1 1 auto;
}

.search fieldset legend{
    display: none;
}
/* heading flower */
.hedingFlower{
    position: relative;
}
.hedingFlower:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 22px;
    background-color: #008E7A;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 5px;
    position: relative;
    top: -2px;
}
.card-title{
    font-size: 18px !important;
    font-family: "Mulish" !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    color: rgba(0, 0, 0, 1) !important;
}
.card-content{
    color: rgba(155, 153, 153, 1) !important;
    font-size: 16px !important;
    font-family: "Mulish" !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}
/* btn css */
.btn-transparent{ 
    color: rgba(0, 142, 122, 1) !important;
    font-size: 14px !important;
    font-family: "Mulish" !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    text-transform: capitalize !important;
}
@media(min-width:920px){

    .theme-space{
        margin: 54px 116px 54px 126px;
    }
}
.tab-view-btn-center{
    width: fit-content;
    max-width: 100%;
    padding: 10.5px 100px;
    border: 0.46px solid #CAD6F1;
    border-radius: 4.56px;
    margin-inline: auto;
}
.tab-view-btn-center .MuiTabs-flexContainer{
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.tab-view-btn-center .MuiTabs-flexContainer .Mui-selected{
    background: #008E7A;
    color: #ffffff !important;
    border-radius: 10px;
    padding-inline: 24px;
}
.tab-view-btn-center .MuiTabs-flexContainer svg path{
    fill: #000000 !important;
}
.tab-view-btn-center .MuiTabs-flexContainer .Mui-selected svg path{
    fill: #ffffff !important;

}
.tab-view-btn-center .MuiTabs-indicator{
display: none;
}
.bb-dashed{
    border-bottom:2px dashed #00000080;
    margin-bottom: 12px;
}
.bb-solid{
    border-bottom:2px solid rgb(0, 0, 0 , 0.2);
    margin-bottom: 12px;
    padding-bottom: 10px;
}
.border-right{
    border-right: 1px solid rgb(0, 0, 0 , 0.5);
    padding-right: 25px;
}
.cancel{
    border: 1px solid #EA4335 !important;
    background: #ffffff !important;
    border-radius: 6px !important;
    font-family: Mulish;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 23.4px !important;
    text-align: center;
    color: #EA4335 !important;    
    padding: 9px 20px !important;
    
}

.pay{
    border: 1px solid #008E7A !important;
    background: #008E7A !important;
    border-radius: 6px !important;
    font-family: Mulish;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 23.4px !important;
    text-align: center;
    color: #ffffff !important;    
    padding: 9px 11px !important;
    min-width: 133px !important;
}
@media screen and (max-width:992px) {

    .cancel,
    .pay {
        font-size: 14px !important;
    }
}
.green-btn{
    background:  #008E7A !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    min-height: 59px !important;
    width: 100%;
}
.search-event-details fieldset{
    height: 56px  !important;
    top: 1px !important;
}

.AttendeesDetailsTable th ,.AttendeesDetailsTable td {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #667382;
    text-transform: uppercase;
}
.cursor-pointer:hover{
    cursor: pointer;
}
.statusbtn{
    width: 100px !important;
    height: 27px !important;
    background: #F1F4F4 !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    border-radius: 20px !important;
    padding: 6px 19px;
}
.adresscstmcard{
    background: rgb(255, 255, 255 , 0.6);
    background-color: rgb(255, 255, 255 , 0.6) !important;
    box-shadow: none !important;
    border: 0.65px solid #DDD8D8;
    border-radius: 0px;
}
.add-address-form-box{
    border: 1px solid #C4C4C4 !important;
}
.add-address-form-box fieldset{
    border-radius: 0px !important;
    border:0px;
    border-bottom: 1px solid #C4C4C4 !important;
}
.add-address-form-box input{
    color: #000;
    font-size: 16px;
    font-family: 'Mulish';
}
.add-address-form-box input::placeholder{
    color: rgba(0, 0, 0, 1);
    opacity: 1;
}

.MuiInputBase-input::placeholder{
    opacity: 1 !important;
}

.add-address-form-box input[type="radio"]{
    visibility:hidden !important;
}
.add-address-form-box li input[type="radio"]:checked ~ li {
    background: #008E7A !important;
    color: white !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    border-color: #008E7A !important;
}



.flag-discount {
    border-radius: 0 6px 6px 0;
    color: #fff;
    display: block;
    float: right;
    padding: 5px 10px;
    background: #93274f;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}
.flag-discount::before,
.flag-discount::after {
    content: "";
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    display: block;
}
.flag-discount::before {
    top: 0;
    border-width: 22px 0 0 15px;
    border-color: #93274f transparent transparent transparent;
}
.flag-discount::after {
    bottom: 0;
    border-width: 0 0 22px 15px;
    border-color: transparent transparent #93274f transparent;
}


input[type="checkbox"] {
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
  }

  input[type="checkbox"]:checked {
    background-color: #008E7A;
    border: 1px solid #008E7A;
  }

  input[type="checkbox"]::before {
    content: "";
    display: none;
    width: 12px;
    height: 12px;
    background-image:url("./assert/image/check.svg") ;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    transition: display 0.2s;
  }

  input[type="checkbox"]:checked::before {
    display: block;
  }
   
  .exportActionMenu .MuiMenu-list{
    padding: "0px",
  }
.mobileLanguageProfile .MuiMenu-list{
    flex-flow: row nowrap !important;
}
.mobileLanguageProfile .MuiMenu-list li {
    padding: 0px 10px !important;
}
  .react-datepicker__input-container input{
    border:2px solid #979797 !important;
    border-radius: 8px !important;
    border-radius: 8px !important;
    border-radius: 8px !important;
    padding-right: 30px !important;
    background: url(./assert/image/icons8-calendar-26.png) no-repeat center right 5px !important;
    background-size: 18px !important;
  }

  
  .react-datepicker__day--outside-month {
    cursor: default;
    color: #ccc;
    pointer-events: none;
}
.react-datepicker__day--in-selecting-range{
    background-color: #008E7A !important;
    color: #fff !important;
}
.MuiMenu-list{
    display: flex !important;
    flex-flow: column !important;
    text-align: left !important;
}
.MuiMenu-list li{
    padding: 6px 16px !important;
    border-bottom:1px solid #f2f2f2 !important;
}
.MuiMenu-list li.Mui-selected{
     background: #008e7aa1 !important;
     border-bottom:1px solid #008e7bf5 !important;
}
.upload-img-box{ 
    max-height: 350px !important;
} 
input:-internal-autofill-selected{
    background: rgba(235, 241, 240, 1) !important;
}

/* ---------------------custom toggle slider -----------------*/

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
    margin: auto 0;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
/* ---------------------custom toggle slider end-----------------*/
.Checkboxsvg svg{position: absolute !important;color: #008E7A;}
.Checkboxsvg {padding-left: 0px !important;}


table.MuiTable-root td {
    display: table-cell !important;
    padding: 16px !important;
}

/* profile page css  */
.profilePageHeading{
    font-size: 20px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin-block:10px 30px !important;
    font-family: "Mulish" !important;
    font-weight: 700 !important;
}
.profileImageBox{
    height: 120px;
    width:120px;
    margin-inline: auto;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%; 
    margin-bottom: 18px;
}
.profileImageBox img{
        width: 100%;
        height: 100%;
        object-fit: cover;
}
.cardBox{
    border: 2px solid rgba(240, 240, 240, 1) !important;
    border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    padding: 60px 40px ;
    background: rgba(255, 255, 255, 1) !important; 
    
}
.boxFirst{
    border-radius: 10px;
    border: 2px solid #F0F0F0;
    background: #FFFF;
    padding:32px 50px; 
}
.boxSecond{
  border-radius: 10px;
  border: 2px solid #F0F0F0;
  background: #FFFF;
  padding:32px 50px; 
  height: "100%",
}

.profileBox{
    position: relative;
}
.profileImageEditIcon{
    background: rgba(0, 142, 122, 1) !important;
    box-shadow: 0px 4.24px 4.24px 0px rgba(0, 0, 0, 0.1);
    color: white !important;
    border-radius:50% !important;
    border: none !important; 
    height: 36px !important;
    width: 36px !important;
    padding: 0 !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.profileName{
    font-size: 22px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin-block:7px !important;
    font-family: "Mulish" !important;
    font-weight: 700 !important;
}

.profileEmail{
    color: rgba(89, 91, 96, 1) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: "Mulish" !important;

}
.hr{
    border: 1px solid rgba(134, 135, 136, 0.2) !important;
}
.profileStatus{
    font-size: 16px !important;
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 500 !important;
    font-family: "Mulish" !important;
    display: block;
    text-align: center;
    margin-block:8px 10px !important;
}
.MuiMenu-list .menuItem{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.MuiMenu-list .menuItem:last-child{
    border-bottom: 1px solid transparent;
}
.MuiMenu-list .Mui-selected.menuItem, .menuItem:hover, .MuiMenu-list .menuItem:last-child:hover{
    border-bottom-color:rgba(0, 142, 122, 1) !important;
    background-color: rgba(0, 142, 122, 0.1) !important;
}
.inputNumber {
box-shadow: 0px  3px 0px 0px rgba(0, 142, 122, 1) !important;
}
.inputNumber .special-label{
    display: none !important;
}

/* mobile number css */
.customInputFilledDesign.mobileNumberDesign{
    position: relative !important;
    background: #fff;
    border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
}
.customInputFilledDesign.mobileNumberDesign.filled-input {
    background:rgba(235, 241, 240, 1) !important;
}
.customInputFilledDesign.mobileNumberDesign .special-label{
    padding-inline: 15px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    font-size: 16px !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    color: rgba(17, 17, 17, 0.5) !important;
    z-index: 0 !important;
}
.customInputFilledDesign.mobileNumberDesign.filled-input .special-label{
    color: rgba(17, 17, 17, 0.5) !important;
    font-size: 18px !important;
    transform: translate(-8px , -25px) scale(0.75) !important;
}
.customInputFilledDesign.mobileNumberDesign .form-control{
    padding-top: 32px;
    background: transparent !important;
    position: relative !important;
    z-index: 1 !important;
    font-size: 16px !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    color: rgba(17, 17, 17, 1) !important;
}

.customInputFilledDesign.mobileNumberDesign .form-control:focus{
    box-shadow: none !important;
}
.customInputFilledDesign.mobileNumberDesign::before{
    content: '';
    height: 10px !important;
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    border-bottom: 1px solid transparent !important;
    transform: scaleX(0) !important;
    transition: all 0.3s linear !important;
    z-index: 0;
}
.customInputFilledDesign.mobileNumberDesign.filled-input::before{
    transform: scale(1) !important;
    transition: all 0.3s linear !important;
    border-radius: 10px !important;
    border-bottom: 1px solid rgba(0, 142, 122, 1) !important;
}

/* custom input field css for filled design */
 .customInputFilledDesign{
    border-radius: 10px !important;
    margin-block:0 20px !important;
}

.companyDetail .customInputFilledDesign{
    margin-bottom: 0 !important;
}
.companyDetail .customInputFilledDesign  .MuiInputLabel-filled{
    color: rgba(17, 17, 17, 0.5) !important;
}
.customInputFilledDesign  .MuiInputLabel-filled, .customInputFilledDesign input, .customInputFilledDesign select{
    font-size: 16px !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    color: #111111 !important;
}
.companyDetail .customInputFilledDesign input{
    color: rgba(17, 17, 17, 1) !important;
    font-size: 14px !important;
    padding-bottom: 10px;
}
.customInputFilledDesign textarea {
    /* height: fit-content !important; */
    padding-block: 15px 5px;
}
.companyDetail .customInputFilledDesign textarea{
    padding-block: 0px 5px;
    /* height: fit-content !important; */
    background: #edf7f6;
    margin-top: 25px;
    line-height: 1.5;
}
.companyDetailSignUp .customInputFilledDesign textarea {
    padding-block: 0px 5px;  
    margin-top: 10px;
    line-height: 1.5;
}
.customInputFilledDesign .MuiFilledInput-root{
    color:rgba(17, 17, 17, 1) !important;
    padding-top: 22px;
    line-height: 1.5 !important;
    background-color: #fff !important;
}
.companyDetail .customInputFilledDesign .MuiFilledInput-root{
    padding-top: 0px;
}

.customInputFilledDesign .MuiFilledInput-root::before{
    border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    border-width: 3px !important;
    border-color: white !important;
}

.companyDetail .customInputFilledDesign .MuiFilledInput-root:before{
border-bottom-color: rgba(0, 142, 122, 1) !important;
border-bottom-width: 1px !important;
}

.customInputFilledDesign .MuiFilledInput-root.Mui-disabled::before {
    border-bottom-style: solid !important;
    border-bottom-color: rgb(255, 255, 255) !important;
}
 .customInputFilledDesign .MuiFilledInput-root::after,  .customInputFilledDesign .MuiFilledInput-root.Mui-focused::after{
    content: "\00a0" !important;
    border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    border-width: 3px !important;
    border-color: rgba(0, 142, 122, 1) !important;
}

 .customInputFilledDesign .MuiInputBase-root{
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    overflow: hidden;
}
.companyDetail .customInputFilledDesign .MuiInputBase-root {
background-color: rgba(0, 142, 122, 0.07) !important;
}

.login-signup-default .customInputFilledDesign .MuiInputLabel-shrink + .MuiInputBase-root, .login-signup-default .customInputFilledDesign .MuiInputLabel-shrink+.MuiInputBase-root input{
    background: rgba(235, 241, 240, 1) !important;
}
.login-signup-default .customInputFilledDesign .MuiInputLabel-shrink + .MuiInputBase-root::before{
    border-bottom: 1px solid rgba(0, 142, 122, 1) !important;
}
.login-signup-default .customInputFilledDesign .MuiInputBase-root::after {
    border-width: 1px !important;
}
.login-signup-default .customInputFilledDesign .MuiInputBase-root .MuiInputAdornment-root button{
    margin-right: 0 !important;
}
.login-signup-default .customInputFilledDesign .MuiInputLabel-filled{
    color: rgba(17, 17, 17, 0.5) !important;
}
.login-signup-default .customInputFilledDesign .MuiInputLabel-filled.MuiInputLabel-shrink {
    font-size: 18px !important;
}
.login-signup-default .customInputFilledDesign input[type="number"]{
    height: auto !important;
    width: 100% !important;
    text-align: start;
}

.customInputFilledDesign.customDatePicker  .react-datepicker-wrapper, .customInputFilledDesign.customDatePicker  .react-datepicker-wrapper input{
    width: 100% !important;
}
.customInputFilledDesign.customDatePicker  .react-datepicker-wrapper input{
    background-color: #fff !important;
    border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    border:1px solid #fff !important;
    padding: 20px 15px !important;
    background-position: center right 15px !important;
}
.customInputFilledDesign.customDatePicker  .react-datepicker-wrapper input:focus, .customInputFilledDesign.customDatePicker  .react-datepicker-wrapper input:not(:placeholder-shown){
    border-color:rgba(235, 241, 240, 1) !important ;
    border-bottom: 1px solid rgba(0, 142, 122, 1) !important;
    background-color: rgba(235, 241, 240, 1) !important;
}
.switch input:checked + .slider{
    background-color: #008E7A !important;

}
/* .boxdesign input[type="number"]{
    text-align: center;
} */
/* footer Card Btn Box */
.footerCardBtnBox{
    padding: 24px 30px;
    margin-block: 20px;
    background: #fff;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.btn-lg{
    padding: 10px 50px !important;
}
.btn-outlined{
    border: 1px solid rgba(0, 142, 122, 1) !important;
    background: #fff !important;
    color: rgba(0, 142, 122, 1) !important;
}
.btn-outlined:hover{
    background: rgba(0, 142, 122, 0.4) !important;
    color: #fff !important;
    border: 1px solid rgba(0, 142, 122, 1) !important;
}
.btn-filled{
    border: 1px solid rgba(0, 142, 122, 1) !important;
    background: rgba(0, 142, 122, 1) !important;
    color:#fff !important ;
}
.btn-filled:hover{
    border: 1px solid rgba(0, 142, 122, 1) !important;
    background: rgba(0, 142, 122, 1) !important;
    color:#fff !important ;
}

.nolegend legend{
    display: none !important;
}
.dayName{
    font-size: 17px !important;
    font-weight: 800 !important;
    color: rgba(0, 0, 0, 1) !important;
    font-family: "Mulish" !important;
    text-transform: capitalize !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}
.react-datepicker__time-container{
    width: 90px;
}
.mobileMenuDatePicker .react-datepicker__month-container{
    display: block;
    width: calc(100% - 90px);
}
.mobileMenuDatePicker .react-datepicker__time-container{
    width: 90px;
}
.datePickerStyle .MuiStack-root{
    width: 100%;
    max-width: 200px;
    margin-inline: auto;
}
.datePickerStyle .MuiStack-root .MuiTypography-root{
    font-size: 16px;
    font-weight: 500;
    font-family: "Mulish" !important;
    color: rgba(0, 0, 0, 1) !important;
    text-transform: capitalize !important;
    border-bottom:  1px solid rgba(0, 0, 0, 0.1) !important; 
    padding-block: 10px !important;
    text-align: center !important;
    margin-bottom: 15px !important;
}.datePickerStyle .MuiStack-root .MuiInputBase-input{
    text-transform: uppercase !important;
    font-size: 30px;
    font-weight: 400;
    font-family: "Mulish" !important;
    color: rgba(0, 0, 0, 0.5) !important;
    text-align: center !important;
    padding-block: 10px;
}
.datePickerStyle .MuiStack-root .MuiInputAdornment-root{
    display: none !important;
}
.dayList{
    font-size: 14px;
    font-weight: 600;
    font-family: "Mulish" !important;
    color: rgba(60, 60, 67, 0.3) !important;
    cursor: pointer !important;
}
.boldWeekDays{
    font-size: 14px;
    font-weight: 600;
    font-family: "Mulish" !important;
    color: rgba(0, 142, 122, 1) !important;
    cursor: pointer !important;
}
.text-green{
    color: rgba(0, 142, 122, 1) !important;
}


/* Radio Box */
.radioBox .Mui-focused{
    color: rgba(17, 17, 17, 0.5) !important;
}
.radioBox .MuiFormControlLabel-root{
    background: #fff;
    padding: 10px;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    margin: 0;
    width: 100%;
}
.radioBox  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: rgba(0, 142, 122, 1) !important;
}

.vendor-form .input-box input, .vendor-form .input-box input[type="number"]{
    padding: 35px 20px 15px !important;
}
.vendor-form .input-box .fileUploadBox  input, .vendor-form .input-box .fileUploadBox  input[type="number"]{
    padding: 25px 20px 15px !important;
}
.dropdown{
    border: transparent !important;
    color: #fff !important;
    outline:none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right:20px;
    background: url(./assert/image/downArrrow.png) no-repeat right 0px center;
    font-family: "Mulish" !important;
    font-size: 14px;
  }
  .dropdown option{
    background: #fff !important;
    color: #000 !important;
    padding: 4px !important;
    font-size: 14px !important;
  }
.ontoggleactive.Mui-selected{
    background-color:#008E7A !important;
    color:#ffffff !important;
    border: 1px solid #008E7A !important;
  }
  .nofieldset fieldset{
    border: 3px solid rgba(0, 0, 0, 0.23) !important;
  }

.nofieldset .Mui-focused fieldset {
    border-top: 3px solid rgba(0, 0, 0, 0.23) !important;
    border-right: 3px solid rgba(0, 0, 0, 0.23) !important;
    border-bottom: 3px solid rgba(0, 142, 122, 1) !important;
    border-left: 3px solid rgba(0, 0, 0, 0.23) !important;
}


.borderinTextField .MuiFilledInput-underline{
    border-radius: 10px;
    border:2px solid #F0F0F0;
}
label{
    font-family: "Mulish" !important;
}
label.MuiFormLabel-root.Mui-focused{
    color: #11111180;
}
.MuiFilledInput-underline.Mui-focused::after{
        border-bottom:2px solid rgba(0, 142, 122, 1) !important;
}

@media screen and (max-width:992px) {
.cardBox{
    padding: 30px 20px;
}
.cardBox .boxSecond{
    padding: 20px 15px;
}
}



.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}


.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  background-color: #fafafa;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  border-bottom: 3px solid #e2e8f0;
  outline: 0;
  position: relative;
}
.StripeElement::after{
    content: "\00a0";
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 13px;
    border-bottom:3px solid #008E7A;
    transform: scaleX(0);
    transform-origin: center;
    border-radius: 10px;
}

.StripeElement--focus::after {
  transform: scaleX(1);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  transition: all 150ms linear;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.cardNumber .MuiTypography-root {
      font-family: "Mulish", sans-serif !important;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1) !important;
    }
 

/* address page map design */
.map-container {
    width: 100%;
    height: 400px; /* Default height for small screens */
    margin-bottom: 12px;
    border-radius: 10px;
  }
  
  @media (min-width: 600px) {
    .map-container {
      height: 500px; /* Height for medium screens */
    }
  }
  
  @media (min-width: 900px) {
    .map-container {
      height: 600px; /* Height for large screens */
    }
  }


  .customDatePicker .react-datepicker-wrapper .customDatePickerInput{
    width: 100% !important;
    border: 1.5px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 5px !important;
    padding: 12px !important;
    font-family: "Mulish" !important;
    font-size: 14px !important;
    min-height: 56px;
    background-position: center right 15px !important;
  } 

  .customDatePicker .react-datepicker-wrapper .customDatePickerInput.error{
    border-color: #d32f2f !important;
  }
  
  .react-datepicker{
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 10px 10px 40px #f2f2f2 !important;
  }
  .react-datepicker-popper{
    z-index: 99 !important;
    box-shadow: 0 0 10px #626262;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
  }
  .react-datepicker-popper .react-datepicker__triangle{
    stroke: #fff !important;
  }
  .react-datepicker__header{
    background: rgba(0, 142, 122, 1) !important;
    color: #fff;
    padding: 20px 15px !important;
    min-height: 85px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  } 
  .react-datepicker__month{
    padding: 20px 15px !important;
  }
.react-datepicker-time__header{
    color: #fff !important;
    font-family: "Mulish" !important;
}
/* .react-datepicker__month-container { 
    width: calc(100% - 90px);
} */
  .react-datepicker__time-box{
    width: 92px !important;
  }

.dateBoxWithTime .react-datepicker__month-container {
    float: left;
    width: calc(100% - 100px);
}
.dateBoxWithTime .react-datepicker__time-container{
    width: 100px !important;
}
 .dateBoxWithTime .react-datepicker__time-box{
    width: 100px !important;
}
.dateBoxWithTime .react-datepicker__navigation-icon--next::before{
    left: -16px !important;
}
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    font-size: 14px !important;
    font-family: "Mulish" !important;
    color: rgba(0, 142, 122, 1) !important;
    font-weight: 500 !important;
    width: 23px;
    height: 23px;
  }
  .react-datepicker__header .react-datepicker__current-month,  .react-datepicker__header .react-datepicker__day-name, .react-datepicker__header .react-datepicker__day, .react-datepicker__header .react-datepicker__time-name{
    font-family: "Mulish" !important;
    color: #fff !important;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, 
  .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, 
  .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, 
  .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background: rgba(0, 142, 122, 1) !important;
    color: #fff !important;
    border-radius: 50% !important;
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: #fff !important; 
    border-width: 2px 2px 0 0 !important;
  }

  .react-datepicker__day--outside-month {
    color: grey !important; /* Set the color for dates outside the current month */
  }
  
  .CardField-expiry.CardField-child .InputElement {
    text-align: center;
}


.subCategoriesBox{
    background: rgb(40 135 91 / 14%);
    padding: 20px 15px;
    border-radius: 10px;

}
.subCategoriesBox .subCategoriesBoxButton{
    background-color: #008E7A !important;
    color: #fff !important;
    cursor: pointer;
}
.subCategoriesBox .subCategoriesBoxButton:hover, .subCategoriesBox .subCategoriesBoxButtonActive{
    background-color: #006e60 !important;
}
.react-multi-carousel-dot-list .react-multi-carousel-dot button{
    height: 10px !important;
    width:10px !important;
    background: #D9D9D9 !important;
    border-color: #D9D9D9 !important;
}
.react-multi-carousel-dot-list .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    background: #fff !important;
    min-width: 42px !important;
    width: 42px !important;
    height: 10px !important;
    border-radius: 10px !important;
    border-color: #fff !important;
}

.landMarkInput::placeholder{
    color:"#000",
}
  @media screen and (max-width: 768px) {
    .dateBoxWithTime  .react-datepicker__month-container { 
        width: calc(100% - 80px);
    }
    .dateBoxWithTime  .react-datepicker__time-container{
        width: 80px !important;
    }
    .dateBoxWithTime  .react-datepicker__time-box{
        width: 80px !important;
    }
    .boxFirst{
        padding: 20px 10px;
    }
    .subCategoriesBox .subCategoriesBoxButton{
        font-size: 14px !important;
        padding: 8px 12px !important;
    }
  }

   

  .stripeButton .StripeElement {
    width: 100px !important;
    min-width: 100px !important;
  }

  


  @media screen and (max-width: 767px) {
    .dateBoxWithTime .react-datepicker__month {
        padding: 20px 0px !important;
    }
     
  }